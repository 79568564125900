import React from 'react'
import { COLOR } from '@farewill/ui/tokens'

import JSONLD from 'components/JSONLD'
import { TRUSTPILOT_RATING_OUT_OF_5, TRUSTPILOT_REVIEWS_TOTAL } from 'config'
import DefaultLayout from 'layouts/DefaultLayout'
import { PRODUCTS } from 'lib/products/constants'
import PROBATE_JSONLD from 'lib/json-ld/probate'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import Content from './Content'

const Apply = (): React.ReactElement | null => (
  <>
    <JSONLD data={PROBATE_JSONLD} />

    <DefaultLayout
      title="Need Help With Probate? | Apply For Probate The Simpler Way"
      description={`Our award-winning team makes applying for probate quick, easy and affordable. Free phone support. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers. Client Care Initiative of the Year 2020.`}
      product={PRODUCTS.PROBATE}
      headerColor={COLOR.WHITE}
      usePhoneNumberTracking
      footerShowFwlsDisclaimer
    >
      <Content ctaText="Get a quote in 5 minutes" />
    </DefaultLayout>
  </>
)

export default Apply
